// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-jets-js": () => import("./../../../src/pages/landing-jets.js" /* webpackChunkName: "component---src-pages-landing-jets-js" */),
  "component---src-pages-landing-yachts-js": () => import("./../../../src/pages/landing-yachts.js" /* webpackChunkName: "component---src-pages-landing-yachts-js" */),
  "component---src-pages-luxury-yachts-js": () => import("./../../../src/pages/luxury-yachts.js" /* webpackChunkName: "component---src-pages-luxury-yachts-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-private-jets-js": () => import("./../../../src/pages/private-jets.js" /* webpackChunkName: "component---src-pages-private-jets-js" */),
  "component---src-pages-rooms-chan-poxila-js": () => import("./../../../src/pages/_rooms/chan-poxila.js" /* webpackChunkName: "component---src-pages-rooms-chan-poxila-js" */),
  "component---src-pages-rooms-garden-js": () => import("./../../../src/pages/_rooms/garden.js" /* webpackChunkName: "component---src-pages-rooms-garden-js" */),
  "component---src-pages-rooms-index-js": () => import("./../../../src/pages/_rooms/index.js" /* webpackChunkName: "component---src-pages-rooms-index-js" */),
  "component---src-pages-rooms-kids-js": () => import("./../../../src/pages/_rooms/kids.js" /* webpackChunkName: "component---src-pages-rooms-kids-js" */),
  "component---src-pages-rooms-machine-js": () => import("./../../../src/pages/_rooms/machine.js" /* webpackChunkName: "component---src-pages-rooms-machine-js" */),
  "component---src-pages-rooms-patron-js": () => import("./../../../src/pages/_rooms/patron.js" /* webpackChunkName: "component---src-pages-rooms-patron-js" */)
}

